<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="配送日期" prop="delivery_date">
          <el-date-picker
            v-model="table.params.delivery_date"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="选择日期"
            @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="检测分类" prop="order_cate_id">
          <el-select v-model="table.params.order_cate_id" filterable placeholder="请选择" @focus="getOrderCateSel" clearable @change="onSearch">
            <el-option
              v-for="item in orderCateArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="供应商名称" prop="supplier_id">
          <el-select v-model="table.params.supplier_id" filterable id="bind_id" clearable placeholder="请输入" @focus="getSupplier" @change="onSearch">
            <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">添加报告</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      :showIndex="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:images="{row}">
        <el-image
          v-for="(img,i) in row.images"
          :key="i"
          style="width: 40px; height: 40px;margin-right:10px;"
          :src="img"
          fit="cover"
          :preview-src-list="row.images"></el-image>
      </template>
      <!-- 创建人为空时，显示供应商 -->
      <template v-slot:admin_name="{row}">
        <span v-if="!!row.admin_name">{{row.admin_name}}</span>
        <span v-else>{{row.supplier_name}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="remove(row)" v-if="is_sys == 1">删除</el-button>
      </template>
    </VTable>

    <!-- 编辑 -->
    <edit ref="edit" @refresh="getTable"></edit>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import NewPwd from '@/components/newPwd.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { getStorage } from '@/storage'

export default {
  name: '',
  components: {
    Edit,
    NewPwd,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      login_account: getStorage('account'),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      is_sys: this.$store.state.is_sys, // 1:只有超级管理员才有列表的删除按钮
      field: [
        { name: "delivery_date", label: "配送日期", hidden: false },
        { name: "order_cate_name", label: "检测分类", hidden: false},
        { name: "images", label: "质检报告", hidden: false},
        { name: "supplier_name", label: "所属供应商", hidden: false},
        { name: "create_time", label: "创建时间", hidden: false},
        { name: "admin_name", label: "创建人", hidden: false},
        { name: "action", label: "操作",width: "100", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          delivery_date: '', //配送日期
          supplier_id: '', // 供应商ID
          order_cate_id: '',//下单分类ID
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      orderCateArr: [], // 下单分类
      supplierArr: [], // 供应商
    
    }
  },
  created() {
    this.getTable();
    // 如果没有删除权限，则不显示操作
    if(this.is_sys != 1) {
      this.field = this.field.filter(item => item.name != 'action')
      delete this.$options.components['template']
    }
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http.get('/admin/report/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 获取供应商
    getSupplier() {
      this.$http.get('/admin/supplier/list', {params:{page:1, count:10000}}).then(res => {
        if(res.code === 1) {
          this.supplierArr = res.data.list;
        }
      })
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 删除
    remove(row) {
      this.setConfirm(
        `确认删除操作？`,
        {  id: row.id },
        "/admin/report/delete"
      );
    },
    
  }
}
</script>
